import type {
  AxiosError,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
  ResponseType,
} from 'axios';
import axios from 'axios';
import { env } from '~/const/env';
import { uriPrefix } from '~/const/api';
import type { PaginationType } from '~/model/PaginationType';
import { getAuthenticationToken } from '~/api/apiConnect';
import type { ContentType, LocaleType } from '~/model/GlobalTypes';
import type { DataCollectionType } from '~/model/DataCollectionType';
import { getGuestToken } from '~/api/apiGuest';
import { CookieName } from '~/const/appConst';

const requestBuilder = ({
  method,
  url,
  data,
  forcePublic = false,
  contentType = 'application/ld+json',
  acceptLanguage,
  noAuthorization = false,
}: {
  method: Method;
  url: string;
  data?: unknown | string;
  forcePublic?: boolean;
  contentType?: ContentType;
  acceptLanguage?: LocaleType;
  noAuthorization?: boolean;
}): AxiosRequestConfig => {
  const token = getAuthenticationToken(CookieName.ACCESS_TOKEN) || getGuestToken();
  const isFixtureToken = ['admin', 'user'].includes(token);
  const clientIdConnectRegex = /S{8}-S{4}-S{4}-S{4}-S{12}/;
  const isImpersonatedToken = clientIdConnectRegex.test(token);
  const isBypass = isFixtureToken || isImpersonatedToken;

  const responseType: ResponseType = 'json';
  return {
    headers: {
      'Accept-Language': acceptLanguage || '',
      ...(!forcePublic
      && typeof window !== 'undefined'
      && token
      && !noAuthorization && {
        Authorization: `Bearer ${token}`,
        ...isBypass && { BYPASS: 'CONNECT' },
      }),
      'Content-Type': contentType,
      'X-AUTH-ORIGIN': 'FO',
      'x-api-key': process.env.REACT_APP_API_KEY,
      ...(env.REACT_APP_API_VERSION && {
        'x-version': env.REACT_APP_API_VERSION,
      }),
    },
    ...(data && { data }),
    method,
    responseType,
    url,
  };
};

export const getRequestBuilder = <T>(
  url: string,
  forcePublic = false,
  acceptLanguage?: LocaleType | null,
  noAuthorization = false,
): AxiosRequestConfig<T> =>
    requestBuilder({
      method: 'GET',
      url,
      forcePublic,
      acceptLanguage,
      noAuthorization,
    });

export const postRequestBuilder = (
  url: string,
  data: unknown | string,
  acceptLanguage?: LocaleType,
  contentType?: ContentType,
): AxiosRequestConfig =>
  requestBuilder({ method: 'POST', url, data, acceptLanguage, contentType });

export const putRequestBuilder = (
  url: string,
  data: unknown | string,
  acceptLanguage?: LocaleType,
  contentType?: ContentType,
): AxiosRequestConfig =>
  requestBuilder({ method: 'PUT', url, data, acceptLanguage, contentType });

export const deleteRequestBuilder = (url: string): AxiosRequestConfig =>
  requestBuilder({ method: 'DELETE', url });

export const initialProps = {};
export const getInitialProps = () => initialProps;

// Get subdata from data
const getJsonData = <T>(data: T): T => {
  if (typeof data === 'string')
    return JSON.parse(data);
  return data;
};

// Get Data from axios response
export const getDataCollection = <T>(data: DataCollectionType<T>) =>
  data && data['hydra:member'];
export const getDataPagination = <T>(data: DataCollectionType<T>) =>
  data && data['hydra:view'];
export const getPaginationPreviousPage = (pagination: PaginationType) =>
  pagination && pagination['hydra:previous'];
export const getPaginationNextPage = (pagination: PaginationType) =>
  pagination && pagination['hydra:next'];
export const getPaginationNextPageNumber = (pagination: PaginationType) =>
  pagination
  && getPaginationNextPage(pagination)
  && Number.parseInt(getPaginationNextPage(pagination).split('=').pop(), 10);
export const isPaginationHasMorePage = (pagination: PaginationType) =>
  pagination && getPaginationNextPage(pagination) !== undefined;
export const getSubRouteFromUri = (uri: string) => uri.replace(uriPrefix, '');
export const getResponseData = <T>(response: AxiosResponse<T>) =>
  response?.data && getJsonData(response.data);
export const getResponseDataCollection = <T>(
  response: AxiosResponse<DataCollectionType<T>>,
) => response?.data && getDataCollection(getJsonData(response.data));

export const processCall = <T>(
  request: AxiosRequestConfig<T>,
  responseFunction = (r: AxiosResponse<T>) => r as any,
  errorFunction = (error: AxiosError) => Promise.reject(error),
): AxiosPromise<T> => {
  return axios(request).then(responseFunction).catch(errorFunction);
};

export const getNextPageRequestFromResponse = (
  response: AxiosResponse,
  acceptLanguage?: LocaleType,
  noAuthorization = false,
) => {
  const data = getResponseData(response);
  const paginationData: PaginationType = getDataPagination(data);
  const nextPageUrl: string = getPaginationNextPage(paginationData);
  return (
    nextPageUrl
    && getRequestBuilder(
      `${env.REACT_APP_API}${getSubRouteFromUri(nextPageUrl)}`,
      false,
      acceptLanguage,
      noAuthorization,
    )
  );
};

// Looping function to get next page concatenate collection
export const getCollectionNextPages = (
  response: AxiosResponse,
  acc: any[],
  acceptLanguage?: LocaleType,
  noAuthorization = false,
) => {
  const collection: any[] = getResponseDataCollection(response);
  const nextPageRequest = getNextPageRequestFromResponse(
    response,
    acceptLanguage,
    noAuthorization,
  );
  const accumulator = [...acc, ...collection];
  return nextPageRequest
    ? processCall(
      nextPageRequest,
      res =>
        getCollectionNextPages(
          res,
          accumulator,
          acceptLanguage,
          noAuthorization,
        ),
    )
    : Promise.resolve(accumulator);
};

export const getUnpaginatedCollection = <T>({
  route,
  queryParams,
  forcePublic = false,
  acceptLanguage,
  noAuthorization = false,
}: {
  route: string;
  queryParams?: string;
  forcePublic?: boolean;
  acceptLanguage?: LocaleType;
  noAuthorization?: boolean;
}): Promise<T> => {
  const params = queryParams ? `?${queryParams}` : '';
  const request: AxiosRequestConfig<DataCollectionType<T>> = getRequestBuilder(
    `${env.REACT_APP_API}${route}${params}`,
    forcePublic,
    acceptLanguage,
    noAuthorization,
  );

  return processCall(
    request,
    (response: AxiosResponse<DataCollectionType<T>>): any => {
      const collection = getResponseDataCollection(response);
      const nextPageRequest = getNextPageRequestFromResponse(
        response,
        acceptLanguage,
        noAuthorization,
      );
      return nextPageRequest
        ? processCall(
          nextPageRequest,
          res =>
            getCollectionNextPages(
              res,
              collection,
              acceptLanguage,
              noAuthorization,
            ),
        )
        : collection;
    },
  ) as any;
};
