import type { AxiosPromise, AxiosResponse } from 'axios';
import { jwtDecode } from 'jwt-decode';
import {
  getResponseData,
  postRequestBuilder,
  processCall,
} from './common/callApi';
import { api } from '~/const/api';
import { env } from '~/const/env';
import { dkconnect } from '~/const/dkconnect';
import {
  getCookie,
  getLocalStorage,
  isCookieAvailable,
  isLocalStorageEnabled,
  removeCookie,
  removeLocalStorage,
  setCookie,
} from '~/utils/utils';
import { CookieName } from '~/const/appConst';

export interface RefreshTokenDataType {
  access_token: string;
  refresh_token: string;
}

const setAccessToken = (access_token: string) => {
  if (access_token)
    setCookie(`${CookieName.ACCESS_TOKEN}=${access_token}`);
};

const setRefreshToken = (refresh_token: string) => {
  if (refresh_token)
    setCookie(`${CookieName.REFRESH_TOKEN}=${refresh_token}`);
};

const handleUserTokenResponse = (response: AxiosResponse) => {
  const data = getResponseData(response);
  if (data?.access_token && data.refresh_token) {
    // store access_token in session storage
    const { access_token, refresh_token } = data;
    setAccessToken(access_token);
    setRefreshToken(refresh_token);
  }
  return response;
};

// DK connect
const getUserToken = (
  postData: { code: string; state?: string },
  isLoggingOut?: boolean,
): AxiosPromise => {
  const { code, state } = postData;
  const request = postRequestBuilder(`${env.REACT_APP_API}${api.USER_TOKEN}`, {
    code,
    redirect_uri: env.REACT_APP_DK_CONNECT_REDIRECT_URI,
    ...(state && { state }),
  });

  return isLoggingOut
    ? Promise.resolve(null)
    : processCall(request, handleUserTokenResponse);
};

export const getAuthenticationToken = (name: CookieName): string => {
  let token = isCookieAvailable() && getCookie(name);
  const tokenFromLocalStorage = isLocalStorageEnabled() && getLocalStorage(name);
  if (!token && tokenFromLocalStorage) {
    token = getLocalStorage(name);
    setCookie(`${name}=${token}`);
    removeLocalStorage(name);
  }

  return token;
};

// REFRESH TOKEN
export const getTokenExpirationDate = (encodedToken: string) => {
  try {
    const token = encodedToken && jwtDecode(encodedToken);
    if (!token?.exp)
      return null;

    const date = new Date(0);
    date.setUTCSeconds(token.exp);
    return date;
  } catch (err) {
    return false;
  }
};

function isBypassBearer(bearer: string) {
  const isFixtureBearer = ['admin', 'user'].includes(bearer);
  const clientIdConnectRegex = /S{8}-S{4}-S{4}-S{4}-S{12}/;
  const isImpersonatedBearer = clientIdConnectRegex.test(bearer);
  return isFixtureBearer || isImpersonatedBearer;
}

const isTokenExpired = (specificToken?: string) => {
  try {
    // Check specific token if param is specified
    // or check to token from localStorage
    const token = specificToken || getAuthenticationToken(CookieName.ACCESS_TOKEN);
    // check the token duration validity if dktconnect is not bypassed
    if (token && !isBypassBearer(token)) {
      const expirationDate = getTokenExpirationDate(token);
      return expirationDate < new Date();
    }
    return !token;
  } catch (err) {
    return true;
  }
};

const clearAccessToken = () => {
  removeCookie(CookieName.ACCESS_TOKEN);
};

const clearRefreshToken = () => {
  removeCookie(CookieName.REFRESH_TOKEN);
};

const clearTokenFirebase = () => {
  removeLocalStorage(dkconnect.tokenFirebase);
};

export const getRefreshTokenScope = (encodedRefreshToken: string) => {
  const refreshToken
    = encodedRefreshToken && jwtDecode<{ scope: string }>(encodedRefreshToken);
  return refreshToken?.scope;
};

const refreshUserToken = async () => {
  const token = getAuthenticationToken(CookieName.REFRESH_TOKEN);
  const scope = getRefreshTokenScope(token);
  const isExpired = isTokenExpired(token);
  // check if refresh token scope contains expected fields
  // and is not yet expired
  const isValidRefreshToken
    = scope
    && scope.includes('country')
    && scope.includes('email')
    && scope.includes('account:birthdate')
    && scope.includes('account:gender')
    && scope.includes('account:profile');

  if (!isValidRefreshToken || isExpired) {
    throw new Error('Invalid refresh token');
  }

  const request = postRequestBuilder(
    `${env.REACT_APP_API}${api.USER_REFRESH_TOKEN}`,
    { refresh_token: token },
  );

  return processCall<RefreshTokenDataType>(request, handleUserTokenResponse);
};

const clearCredentials = () => {
  clearAccessToken();
  clearRefreshToken();
  window.location.reload();
};

const clearStorage = (): void => {
  clearAccessToken();
  clearRefreshToken();
  clearTokenFirebase();
};

export const apiConnect = {
  clearAccessToken,
  clearCredentials,
  clearRefreshToken,
  clearStorage,
  clearTokenFirebase,
  getAuthenticationToken,
  getRefreshTokenScope,
  getTokenExpirationDate,
  getUserToken,
  isTokenExpired,
  refreshUserToken,
  setAccessToken,
  setRefreshToken,
};
